var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.init
    ? _c(
        "v-dialog",
        {
          attrs: {
            justify: "center",
            value: _vm.value,
            persistent: "",
            "max-width": "450",
          },
        },
        [
          !_vm.init
            ? _c(
                "v-card",
                { attrs: { color: "fill" } },
                [
                  _c("v-spacer"),
                  _c("v-card-title", { staticClass: "font-weight-light" }, [
                    _vm._v("Ad Status"),
                  ]),
                  _c(
                    "v-card-text",
                    [
                      _c("span", { class: _vm.body }, [
                        _vm._v(" " + _vm._s(_vm.message) + " "),
                      ]),
                      _vm.rejectedAd
                        ? _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                [
                                  _c("v-textarea", {
                                    attrs: {
                                      outlined: "",
                                      dense: "",
                                      "background-color": "input",
                                      placeholder:
                                        "Please leave brief rejection reason...",
                                      "no-resize": "",
                                      rows: "6",
                                    },
                                    model: {
                                      value: _vm.note.note_text,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.note, "note_text", $$v)
                                      },
                                      expression: "note.note_text",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "v-card-actions",
                    [
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          class: _vm.body,
                          attrs: { text: "" },
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.$emit("update:value", false)
                            },
                          },
                        },
                        [_vm._v("Cancel")]
                      ),
                      _c(
                        "v-btn",
                        {
                          staticClass: "green white--text ml-2",
                          class: _vm.body,
                          attrs: {
                            loading: _vm.loading,
                            disabled: _vm.rejectedAd && !_vm.note.note_text,
                          },
                          on: { click: _vm.updateStatus },
                        },
                        [_vm._v("Confirm ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }